import FAQLinks from '@/modules/User/statics/faqLinks';
import ProductType from '@/statics/productType';
import i18n from '@/helper/i18n';
import { BASIC_CREDITOR_VOUCHER_TYPE_GROUPS } from '@/modules/User/statics/creditorVoucherType';
import { BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS } from '@/statics/voucherStatusToShow';
import { BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS } from '@/statics/voucherSubStatus';
import { BASIC_VOUCHER_TYPE_GROUPS } from '@/statics/voucherType';
import { FACTORING_PERMISSIONS } from '@/statics/productPermissions';
import {
  IMPRINT_TEAMFAKTOR,
  PRIVACY_POLICY_TEAMFAKTOR,
  PRODUCT_INFO_DV,
  SERVICE_HOTLINE_TEAMFAKTOR,
  SERVICE_MAIL_TEAMFAKTOR
} from '@/statics/customerServiceInformation';
import { TURNOVER } from '@/helper/createCreditorFactory';

const PRODUCT_NAME = 'DV FactoringPlus';

const DV_FACTORING_CONFIG = {
  type: ProductType.DV_FACTORING,
  productName: PRODUCT_NAME,
  logo: require('@/assets/DV-logo.svg'),
  partnerLogo: require('@/assets/teamfaktor-logo.svg'),
  partnerLogoSmall: require('@/assets/teamfaktor-logo-small.svg'),
  favIcon: '/favicons/faviconDV.ico',
  theme: 'dv' /* matches key of $theme-base in styles/themes/index.scss */,
  background: null,
  placeLogoInHeader: false,
  serviceHotline: SERVICE_HOTLINE_TEAMFAKTOR,
  serviceMail: SERVICE_MAIL_TEAMFAKTOR,
  infoUrl: PRODUCT_INFO_DV,
  privacyPolicyUrl: PRIVACY_POLICY_TEAMFAKTOR,
  imprintUrl: IMPRINT_TEAMFAKTOR,
  withPurchaseAssurance: false,

  voucherStatusToShowGroups: BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS,
  voucherSubStatusFilter: BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS,
  voucherTypeGroups: BASIC_VOUCHER_TYPE_GROUPS,
  creditorVoucherTypeGroups: BASIC_CREDITOR_VOUCHER_TYPE_GROUPS,

  permissions: {
    ...FACTORING_PERMISSIONS,
    helpCenter: {
      ...FACTORING_PERMISSIONS.helpCenter,
      tabTutorials: true
    }
  },

  onboarding: {
    maxCreditorLimitInCent: 75000000,
    useCalculatedCreditorLimit: true,
    rules: {
      [TURNOVER]: 'required'
    }
  },

  faq: [FAQLinks.ENCASHMENT, FAQLinks.FACTORING],

  documents: {
    masterData: [
      {
        title: i18n.t('masterData.increaseCreditorLimitForm'),
        path: '/assets/masterData/SMAFA/Antrag Erhöhung Ankaufrahmen DV FPL_202501.pdf'
      },
      {
        title: i18n.t('masterData.infoChangeBankDetails'),
        path: '/assets/masterData/SMAFA/Vertragsergänzungen DV FPL__Änderung Bankverbindung.pdf'
      },
      {
        title: i18n.t('masterData.notificationLetter'),
        path: '/assets/masterData/SMAFA/Notifikationsschreiben_Zusammenarbeit_TeamFaktor.docx'
      }
    ],

    sepa: {
      template: {
        title: i18n.t('permanentDebts.pdf.sepaTemplate'),
        path: '/assets/sepa/Vorlage_Einzugsermächtigung.pdf'
      }
    },

    upload: {
      rules: '/assets/upload/TF_Factoringregeln.pdf',
      tips: '/assets/upload/DV/Hinweise_zum_Rechnungsaufbau.pdf'
    }
  },

  translations: {}
};

export default DV_FACTORING_CONFIG;
