import FAQLinks from '@/modules/User/statics/faqLinks';
import ProductType from '@/statics/productType';
import i18n from '@/helper/i18n';
import { BASIC_CREDITOR_VOUCHER_TYPE_GROUPS } from '@/modules/User/statics/creditorVoucherType';
import { BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS } from '@/statics/voucherStatusToShow';
import { BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS } from '@/statics/voucherSubStatus';
import { BASIC_VOUCHER_TYPE_GROUPS } from '@/statics/voucherType';
import { FACTORING_PERMISSIONS } from '@/statics/productPermissions';
import {
  IMPRINT_TEAMFAKTOR,
  PRIVACY_POLICY_TEAMFAKTOR,
  PRODUCT_INFO_TEAMFAKTOR,
  SERVICE_HOTLINE_TEAMFAKTOR,
  SERVICE_MAIL_TEAMFAKTOR
} from '@/statics/customerServiceInformation';
import {
  ASSET_CONSULTANT,
  ASSET_CONSULTANT_NUMBER,
  DEPARTMENT,
  INTERMEDIARY_NUMBER,
  TURNOVER
} from '@/helper/createCreditorFactory';

const PRODUCT_NAME = 'FactoringWerk';

const FW_FACTORING_CONFIG = {
  type: ProductType.FW_FACTORING,
  productName: PRODUCT_NAME,
  logo: require('@/assets/teamfaktor-logo.svg'),
  logoSmall: require('@/assets/teamfaktor-logo-small.svg'),
  partnerLogo: '',
  favIcon: '/favicons/faviconTeamfaktor.ico',
  theme: 'teamfaktor' /* matches key of $theme-teamfaktor in styles/themes/index.scss */,
  background: null,
  placeLogoInHeader: false,
  serviceHotline: SERVICE_HOTLINE_TEAMFAKTOR,
  serviceMail: SERVICE_MAIL_TEAMFAKTOR,
  infoUrl: PRODUCT_INFO_TEAMFAKTOR,
  privacyPolicyUrl: PRIVACY_POLICY_TEAMFAKTOR,
  imprintUrl: IMPRINT_TEAMFAKTOR,
  withPurchaseAssurance: false,

  voucherStatusToShowGroups: BASIC_VOUCHER_STATUS_TO_SHOW_GROUPS,
  voucherSubStatusFilter: BASIC_VOUCHER_SUB_STATUS_FILTER_OPTIONS,
  voucherTypeGroups: BASIC_VOUCHER_TYPE_GROUPS,
  creditorVoucherTypeGroups: BASIC_CREDITOR_VOUCHER_TYPE_GROUPS,

  permissions: FACTORING_PERMISSIONS,

  onboarding: {
    maxCreditorLimitInCent: 75000000,
    useCalculatedCreditorLimit: true,
    blacklist: [INTERMEDIARY_NUMBER, ASSET_CONSULTANT, DEPARTMENT],
    rules: {
      [ASSET_CONSULTANT_NUMBER]: 'required|digits:6',
      [TURNOVER]: 'required'
    },
    setUpFeeValues: [49900, 24900, 0]
  },

  faq: [FAQLinks.ENCASHMENT, FAQLinks.FACTORING],

  documents: {
    masterData: [
      {
        title: i18n.t('masterData.increaseCreditorLimitForm'),
        path: '/assets/masterData/FW/Antrag Erhöhung Ankaufrahmen FactoringWerk_202501.pdf'
      },
      {
        title: i18n.t('masterData.infoChangeBankDetails'),
        path: '/assets/masterData/FW/Vertragsergänzungen FactoringWerk__Änderung Bankverbindung.pdf'
      },
      {
        title: i18n.t('masterData.notificationLetter'),
        path: '/assets/masterData/FW/Notifikationsschreiben_Zusammenarbeit_TeamFaktor.docx'
      }
    ],

    sepa: {
      template: {
        title: i18n.t('permanentDebts.pdf.sepaTemplate'),
        path: '/assets/sepa/Vorlage_Einzugsermächtigung.pdf'
      }
    },

    upload: {
      rules: '/assets/upload/TF_Factoringregeln.pdf',
      tips: '/assets/upload/FW/Factoring 1x1.pdf'
    }
  },

  translations: {
    onboarding: {
      assetConsultantNumber: '@:onboarding.intermediaryNumberFull',
      assetConsultantData: '@:onboarding.intermediaryData'
    }
  }
};

export default FW_FACTORING_CONFIG;
